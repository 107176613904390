/* default css */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/********************************************** helper css **********************************************/

/*** TYPOGRAPHY  ***/
#root, .ui.header, .ui.menu, .ui.button{
  font-family: 'Noto Sans CJK TC', Noto, Helvetica, Arial, 'Microsoft JHengHei', sans-serif !important;
}


#root {
  background-color: #fffbf2;
  color: #333;
  position: fixed;
  top: 0; bottom: 0; left: 0; right: 0;
  overflow-y: scroll;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.full-height {
  min-height: 100vh;
}

/* main content container */
#main {
  display: block;
  flex: 1;
  padding-bottom: 2rem;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  z-index: 0;
}
#main.user-panel-no-data{
  display: flex;
}

.user-panel-header{
  flex: none;
  max-height: 11rem;
}

/* nav menu font bold */
.ui.menu .item {
  font-weight: bolder !important;
}

#nav-logo{
  width: unset;
  height: 60px;
}

/* cursor */
.cursor {
  cursor: pointer;
}

/* from group margin */
.form-group-margin {
  margin-bottom: 2em !important;
}

/* form group no margin */
.no-bottom-margin {
  margin-bottom: 0 !important;
}

/* margin for fields in form group */
.fields.grouped>.field {
  margin-bottom: 1em !important;
}

/* New and Old Labels for Videos */
.new-label, .old-label{
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  margin-left: 0.5em;
}
.new-label{
  color: red;
}

.ui.header.old-label{
  color: transparent;
  pointer-events: none;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;   /* Firefox all */
  -ms-user-select: none;    /* IE 10+ */
  user-select: none;      /* Likely future */
}

/* Beautify Video Title */
.ui.header.video-title{
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Remove sick blue link color in BriefVideo*/
a.brief-video, a.brief-video:hover,a.brief-video:active, .main a .avatar-name, .user-panel-header a .avatar-name{
  color: initial;
}

/* I dunno dfq why seondary menu has offset */
.ui.secondary.menu{
  margin-left: -1.35rem !important;
  margin-top: 0;
}

.video-segment{margin-top: 1em;}

.menu .ui.dropdown>.dropdown.icon{
  display: block;
  margin: 0.5em 0 0 0;
}
.avatar{border-radius: 50%;}
.avatar-name{text-align: center;}
.user-panel-header .header.avatar-name{margin-top: 0.5rem;}
.ui.grid.user-panel-header{
  background-color: rgba(255, 255, 255, 0.3);
  border-bottom: 1px rgba(138,138,138,0.2) solid;
}

#top-nav-bar, .no-margin{
  margin: 0 !important;
}
#top-nav-bar>.left.item{margin: 0 !important;}

a.ui.basic.orange.label.contact-us:hover{
  background-color: hsl(24, 89%, 56%)!important;
  color: #fff!important;
}

#user-header{margin-bottom: 1rem;}

.fullwidth{
  width: 100%;
}

.ui.grid>.user-video{
  width: 100%;
  padding: 0 2rem;
}

#top-nav{
  position: relative;
  z-index: 1;
}
#top-mobile-nav{
  margin-top: 0;
  position: absolute;
  right: 0;
  left: 0;
  width: auto;
  z-index: -1;
  transform: translateY(-100%);
}
#top-mobile-nav.open{
  transform: translateY(0);
  transition: 0.5s transform;
}

.main.ui.grid>div{
  padding-left: 0;
  padding-right: 0;
}

.ui.grid{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.square-thumbnail, .avatar {
  background-position: center;
  background-size: cover;
  padding-top: 75%;
}

.brief-video{
  display: flex;
  flex-direction: column;
}

.square-thumbnail+.attached.segment{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}
.attached.segment>table{
  margin-top: auto;
}

h4.ui.header.avatar-name{
  margin-top: 0.5em;
}


.user-video-detail-col, .user-video-image-col, .full-video-info{
  padding-bottom: 0 !important;
}
.user-video-detail-col{
  padding-right: 0 !important;
}

.ui.button.pend_approval{
  pointer-events: none;
}

.user-video-detail-col>.ui.table, .square-thumbnail-warp+.ui.table{
  margin-bottom: 0 !important;
}

.user-desc{
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

#main .ui.grid>.row:first-of-type,
#main .ui.grid>.row:first-of-type .column{
  padding-top: 0
}

#main>.ui.grid{
  width: 100%;
}

.sub-date{
  text-align: center;
  margin-top: 0;
}

h1.ui.header{
  margin-bottom: 0.5rem !important;
}

.comment-buttons{
  opacity: 0.8;
}

.desc-text{
  white-space: pre-line;
}

hr {
  opacity: 0.25;
  border-width: 0.5px;
}
.avatar-wrap{
  color: inherit;
  text-align:center;
  margin-top: auto;
  margin-bottom: auto;
  
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.avatar-wrap:hover{
  color: inherit;
}
.avatar-wrap>.ui.mini.image{
  width: 4rem;
}
#top-nav-bar>.avatar-wrap{
  color: #eee;
}
.dropdown>.ui.mini.image.avatar-wrap{
  width: 6rem;
}

.dropdown .avatar{
  width: 80%;
  margin: 0 10% 0.5rem;
  padding-top: 80%;
}

.ui.form .field>label{
  text-align: left;
}

.inline{display: inline-block; vertical-align: middle;}
.no-flex{display: block;}

.segment.selected{
  outline: 0.5rem #0a0 solid;
}
.ui.header:first-child {
  margin-top: 0 !important;
}

.ui.rail{width: auto !important; width: initial !important;}
.ui.rail.left, .ui.rail.right{width: 100% !important;}

.contributors>.grid>.column {
  min-width: 7rem;
}

.left-padded {
  margin-left: 1rem !important;
}

.right-padded {
  margin-right: 1rem !important;
}

#main .ui.embed>.placeholder{
  left: 50%;
  width: auto;
  width: initial;
  transform: translateX(-50%);
}

/* spinner icon */
.hourglass.icon{
  position: absolute;
  right: 10px;
  bottom: 10px;
  pointer-events: none;
  transform: none;
  animation: none;
}

.hourglass.icon.white::before{
  color: white;
}

.hourglass.icon.active{
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: spinner;
}

@keyframes spinner{
  from{transform: none;}
  40%{transform: rotate(180deg);}
  50%{transform: rotate(180deg);}
  90%{transform: rotate(360deg);}
  100%{transform: rotate(360deg);}
}

/***************************** RESPONSIVE *****************************/
@media only screen and (max-width: 1023px){
  .ui.stackable.menu {
    flex-direction: column;
  }
  .ui.stackable.menu .item {
    width: 100%!important;
  }
  .ui.stackable.menu .item:before {
    position: absolute;
    content: '';
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(34,36,38,.1);
  }
}

@media only screen and (min-width: 991px){
  .column.related-videos{width: 402px !important;}
}
@media only screen and (max-width: 990px){
  #search-panel .column{box-shadow: none;}
}
@media only screen and (min-width: 768px){
  .ui.container {
    width: 94% !important;
  }
  .column.related-videos{width: 40.6% !important;}
  .ui.grid.user-panel-header{padding: 1em 1em 0em;}
  .video-panel-video{flex: 1;}
  .ui.column.grid>.wide.column.user-video-image-col{
    width: 17.5rem !important;
  }
  .ui.button.pend_approval{
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width:767px){
  .ui.grid>.full-video-info.row.column.stackable>.column {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  #main>.ui.container{
    width: 100% !important;
    margin: 0 !important;
  }
  .video-segment .column:not(.ui) {
    padding: 0 !important;
  }
  .column.related-videos{width: 100% !important;}
  .brief-video+.brief-video .square-thumbnail{margin-top: 2rem;}
  .ui.header.old-label{display: none;}
  #root .ui.grid{
    margin-left: 0;
    margin-right: 0;
  }
  #main .ui.grid>*{
    padding-left: 0;
    padding-right: 0;
  }
  .ui.grid.user-panel-header{
    flex-direction: column;
  }
  .user-panel-header{
    max-height: 15rem;
  }
  .user-panel-header>.row:first-child{
    flex: 1;
  }
  .user-panel-header.grid>.row:last-child{
    height: 3rem;
    padding: 0;
    flex: none;
    padding: 0;
  }
  
  .form-group-margin {
    margin-bottom: 1em !important;
  }

  .ui.menu.stackable .icon.item>.icon{
    margin:0;
  }
}

.float-left{
  float: left;
}

.float-right{
  float: right;
}

.uncategorized{
  color: 'red';
  opacity: 0.6;
}

.no-overflow{
  overflow: hidden !important;
}

.youtube-wrapper{
  height: 0;
  padding-top: 56.25%;
  position: relative;
}

.youtube-player{
  top: 0;
  left: 0;
  position: absolute;
}

dt{
  font-weight: bold;
}

.video-info{
  margin-bottom: 0.5em;
}

.video-info>ul, dl {
  margin: 0;
}

#top-nav-bar .ui.simple.active.dropdown>.menu, #top-nav-bar .ui.simple.dropdown:hover>.menu{
  overflow-y: scroll;
}

.ui.dropdown.item .scrolling.menu{
  border-radius: 0 0 .28571429rem .28571429rem !important;
}

.ui.menu .fake_active.item {
  background: rgba(0,0,0,.05);
  color: rgba(0,0,0,.95);
  font-weight: 400;
  box-shadow: none;
}

.related.brief-video, .desc-row{
  display: flex;
  flex-direction: row;
}
.desc-row{
  padding-bottom: 0.5rem;
}
.related.brief-video .square-thumbnail{
  width: 168px;
  padding: 0;
}

.desc-item{
  flex: 1;
}

.desc-title{
  font-weight: bold;
}

#top-nav .unset-cursor{
  cursor: unset;
}

.brief-video .star-ratings{
  width: 7.5em;
}

.ui.checkbox{
  margin-right: 1rem;
  height: 2em;
}

.full-video-info{
  font-size: 1.2em;
  line-height: 1.2;
}

.red{color: red}

.video-excerpt{
  height: 1.2em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.no-wrap{
  white-space: nowrap;
}


.ui.label.tag{margin: .5em .7142857em;}
.ui.label.tag:first-child{margin-left: 1em;}

i.light.grey.icon{
  color: rgb(203, 211, 227) !important;
}

.fav-icon-container{
  margin: 0 auto;
  width: 80px;
}

.ui.accordion{
  margin-bottom: 1em;
}